import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const ContactBranch = () => {
    var settings4 = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,       
        controls: true,
        nav: true,
        dots: false,
        items: 1
    };
    return(
        <> 
            <OwlCarousel className='owl-theme branch_carowsel' loop margin={10} {...settings4}>
                <div className="item">
                    <div className='branch-blk'>
                        <h4>OUR BRANCHES</h4>
                        <p><b>Palwal Campus</b></p>
                        <p><i class="bi bi-geo-alt"></i>
                        Tagore Public School, HUDA Sector-2, Palwal, Haryana 121102
                        </p>
                        <p><i class="bi bi-telephone-fill"></i>  +91 089307 29377</p>
                        <p><i class="bi bi-envelope"></i> <a href="mailto: tagorepalwal@gmail.com">tagorepalwal@gmail.com</a></p>
                        <button className='button'><span>Locate Us <i class="bi bi-arrow-right"></i></span></button>
                    </div>
                </div>
                <div className="item">
                    <div className='branch-blk'>
                        <h4>OUR BRANCHES</h4>
                        <p><b>Faridabad Campus</b></p>
                        <p><i class="bi bi-geo-alt"></i>
                        Block G, Sector 89, Faridabad
                        </p>
                        <p><i class="bi bi-telephone-fill"></i> +91-8527733139</p>
                        <p><i class="bi bi-envelope"></i> <a href="mailto:tagoreschoolfbd89@gmail.com">tagoreschoolfbd89@gmail.com</a></p>
                        <button className='button'><span>Locate Us <i class="bi bi-arrow-right"></i></span></button>
                    </div>
                </div>
            </OwlCarousel> 
        </>
    )
}
export default ContactBranch