import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
import { Link } from 'react-router-dom'
const OnlineRegistration = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
   const fetchData = async () => {
     try {
       const data = await getOnlineRegistration();
       setData(data);
     } catch (error) {
       console.error("Error fetching online registration data:", error);
     }  
   };
   fetchData();
 }, []);
 const emptyArray =[]
  return (
    <>
     {data.length > 0 ? (
            data.map((item, index) => (


              <div className='button online-registration'>
                            <Link to="/" target="_blank">
                                <p>{item.title}</p>
                                {item.attachments.length >0 ? ( <Link to={`https://webapi.entab.info/api/image?url=${item.attachments[0]}`} target="_blank" className=""> <i className="bi bi-paperclip"></i></Link>):(<></>)}
                            </Link>
                        </div>

 
     ))
    ) : (
      emptyArray.map((item, index) => <div key={index}></div>)
    )}
    </>
  )
}

export default OnlineRegistration
