import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { getNews } from "../Service/Api";
const News = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNews();
      setData(datas);
    };
    getData();
  }, []);
  const placeholderEntries = [
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" }
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };



  return (
    <>
      <div className="eventbg">
        <Slider {...settings}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div key={index}>
                <div className="homeeventbox">
                  <div className="homeeventboximg">
                    <div className="overlay">
                      <button className="dark-button"><Link to="/News">Read More</Link></button>
                    </div>
                    {item.images > 0 ? (
                      <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images}`} className="img-fluid" alt="Tagore Public School, Ballabhagarh" />
                    ) : (
                      <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/about.jpg" className="img-fluid" alt="Tagore Public School, Ballabhagarh" />
                    )}
                  </div>
                  <div className="homeeventboxdesc">
                    <div className="homeeventboxtitle">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            placeholderEntries.map((item, index) => (
              <div key={index}>
                <div className="homeeventbox">
                  <div className="homeeventboximg">
                    <div className="overlay">
                      <button className="dark-button">Read More</button>
                    </div>
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/about.jpg" className="img-fluid" alt="Placeholder" />
                  </div>
                  <div className="homeeventboxdesc">
                    <div className="homeeventboxtitle">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
        <div className="mainbtn button">
          <Link to="/News">
            <button className='button'>
              <span>Read More <i className="bi bi-arrow-right"></i></span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default News;
