import React from 'react'
import Popup from '../Component/Popup'
import Header from '../Component/Header'
import HomeSlider from '../Component/HomeSlider' 
import Events from '../Component/Events'
import News from '../Component/News'   
 import Facilities from'../Component/Facilities'
import Gallery from '../Component/Gallery'
import Message from '../Component/Message' 
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom' 
const Home = () => {
  return (
    <>
      {/* <Campuscare /> */}
      <Popup />
      <Header /> 
      <HomeSlider />
    <div className="facilities">
      <div className='d-flex'>
        <div className='faci_blk'>
          <div>
          
          <p>Mission</p>
          </div>
        </div><Link to="/Vision">
        <div className='faci_blk'>
        
          <div>
          
          <p>Vision</p>
         
          </div>
        </div> </Link>
        <div className='faci_blk'>
          <div>
          
          <p>Alumni</p>
          </div>
        </div>
        <div className='faci_blk'>
          <div>
          
          <p>Achievements</p>
          </div>
        </div>
      </div>
    </div>
      <div className="aboutsec">
        <div className="container fulwidth">
          <div className="row">
          <div className="col-lg-5">
              <div className="about-img">
                <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/about.png' className='img-fluid' alt="Tagore Public School, Ballabhagarh" />
              </div>
            </div>
            <div className="col-lg-7 about-bg">
              <div class="about">
                <div className='title'>
                  <h3>About <span>Us</span></h3> 
                </div>
                <p><span>Founded in 1995,</span> TAPS is situated in NCR (National Capital Region) in Ballabgarh, Faridabad, adjacent to New Delhi. It houses <b>3500 students</b>, coming from all walks of life and a dedicated <b>team of 150, proficient and skilled teachers and lecturers.</b> The school is headed by <b>Director Ms. Manorma Arora, a Rajat Jayanti and State Award Winner</b>. She is a dynamic personality with a clarity of vision, an educationist par excellence and a learned philosopher.<br></br>
Since it’s inception the school has provided congenial & conducive atmosphere for the multifaceted development of the students. The activity based curriculum for class <b>Nursery to XII,</b> is framed to inculcate & instill educational, ethical & professional attributes in the students. It has been an endeavour on the part of the management & the staff to strive for excellence, enabling the institute to yield young, enterprising professionals & human beings.</p>
                <Link to="/AboutUs"><button className='button'><span>Read More <i class="bi bi-arrow-right"></i></span></button></Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>  
    <Facilities />
      <div className='events padded-sec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-6 col-md-12'>
            <div className='title text-center'> 
                <h3>Events <span>section</span></h3>
              </div>
              <Events />
            </div>
            <div className='col-xl-6 col-md-12'>
            <div className='title text-center'> 
                <h3>Latest News/<span>Announcement</span></h3>
              </div>
              <News />
            </div>
          </div>
        </div>
      </div>
      <div className="message_section">
        <div className="container fulwidth">
          <div className="row">
            <div className="col-lg-12 section-title">
              <div className='title messgae-title'> 
                <h3><div>FROM THE DESK OF<br></br><span>Message section</span></div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/quote.gif"></img></h3>
              </div>
            </div>
            <div className="col-lg-12">
              <Message />
            </div>
          </div>
        </div>
      </div>
     
     
    
      <div className="homegallery padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className='col-md-12 section-title'>
              <div className='title gallery-sec'> 
                <h3>School <span>gallery</span></h3>
                
              </div>
            </div>
            <div className="col-lg-12">

              <Gallery />
             
            </div>
          </div>
        </div>
      </div>
 
      <Footer />
    </>
  )
}

export default Home