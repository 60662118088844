import React, { useState } from "react";

const Facilities = () => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState(1);

  // Details for each tab
  const tabDetails = {
    1: {
      title: <>Highly Equipped <span>Campus</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
    2: {
      title: <>Robotic <span>Lab</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
    3: {
      title: <>Greenery with <span>Security</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
    4: {
      title: <>AI Integrated <span>Campus</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
    5: {
      title: <>Erp Governed<span> Campus</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
    6: {
      title: <>Transportation <span>Services</span></>,
      description: "We have a highly equipped campus for the students to enhance their skills and develop their personality.",
    },
  };

  // Handler for changing the active tab
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className='facilities_sec'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='shape_faci'>
              <div className='d-flex'>
                <div onClick={() => handleTabChange(1)}>
                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/cbse.png" alt="CBSE" />
                  <p>CBSE Disclosure</p>
                </div>
                <div onClick={() => handleTabChange(2)}>
                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/admission.png" alt="Admission" />
                  <p>Admission Information</p>
                </div>
                <div onClick={() => handleTabChange(3)}>
                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/calender.png" alt="Holidays" />
                  <p>List of Holidays & Planner</p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-7'>
            <div className='d-flex'>
              <div className='detail-blk'>
                {Object.keys(tabDetails).map((index) => (
                  <div
                    key={index}
                    className={`facility_selection cir${index}_sel ${activeTab === parseInt(index) ? 'active' : ''}`}
                    onClick={() => handleTabChange(parseInt(index))}
                  >
                    <div className='title'>
                      <h3>{tabDetails[index].title}</h3>
                    </div>
                    <p>{tabDetails[index].description}</p>
                  </div>
                ))}
              </div>
              <div className='circleDiv'>
                {Object.keys(tabDetails).map((index) => (
                  <div
                    key={index}
                    className={`abs-blk cir${index} ${activeTab === parseInt(index) ? 'active' : ''}`}
                    onClick={() => handleTabChange(parseInt(index))}
                  >
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/cir${index}.png`} className='img-form' alt={`Circle ${index}`} />
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/cir${index}.gif`} className='gif-form' alt={`Circle ${index} GIF`} />
                  </div>
                ))}
                <h2>Facilities</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
