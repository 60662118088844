import React from 'react' 
import { useState, useEffect } from 'react'; 
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Breadcrumb from '../Component/Breadcrumb';
import { Link } from 'react-router-dom'; 
import { getCircular } from '../Service/Api';
const Result = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedClass, setSelectedClass] = useState('9'); // Default to Class 9

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for DD/MM/YYYY format
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCircular();
      console.log('Fetched data:', response); // Debug: Check the structure of the fetched data
      setData(response);
    };
    fetchData();
  }, []);

  // Handle change for class selection
  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      console.log('Selected Class:', selectedClass); // Debug: Log the selected class
      console.log('Current Data:', data); // Debug: Log the current data

      // Adjust the filtering logic to match class with the select dropdown values
      const filtered = data.filter(
        (item) => item.category === 'Result'  // Ensure 'class' is correctly parsed
      );
      
      console.log('Filtered Data:', filtered); // Debug: Log the filtered data
      setFilteredData(filtered);
    };
    filterData();
  }, [selectedClass, data]); // Run this effect whenever selectedClass or data changes

  const renderTable = () => (
    <div className="col-lg-12">
    <div className="table-responsive maintable"><table className="table"><thead>
    <tr>
    <th width="10%" className="text-center">S.NO </th><th width="60%" className="text-center"> Result</th><th width="30%" className="text-center">Download/View </th></tr>
  </thead>
  
  <tbody>
 {filteredData.map((item, index) => (
   <tr key={item._id}>
     <td>{index + 1}</td> 
    <td>{item.title}</td>
     <td><Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank">View</Link></td>
    
   </tr>
 ))}
</tbody> 
</table>
</div>

</div>
  );
  return (
   <>
   <Header />
            <Breadcrumb mid="About" breadcrumbName="Result" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>Result</span> </h3>
                            </div>
                        </div>
                      </div>
               <div className="row"> 
              
              </div>
              {renderTable()}
           </div>
       </div>
   <Footer />
  
   </>
  )
}

export default Result
