import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const ContactUs = () => {
    return (
        <>
            <Header />
            {/* <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us </li>
            </ul>
       </div> */}
            <Breadcrumb breadcrumbName="Contact Us" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Contact </span> Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>    
                        <div className='col-lg-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112362.68510260395!2d77.25249468200866!3d28.34875283828096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390cdb7836715451%3A0x6085fec5ca1cc0b!2sSector%203%2C%20Ballabhgarh%2C%20Faridabad%2C%20Haryana%20121004!3m2!1d28.3487776!2d77.3348961!5e0!3m2!1sen!2sin!4v1726058148929!5m2!1sen!2sin" width="100%" height="350" style={{ border: " 0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>                    
                        <div className='col-lg-12'>
                            <div className="contact-container">
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/location1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Address</h6>
                                        <p><Link to="https://maps.app.goo.gl/3aEy9QW6euYGx1cbA" target="_blank">
                                        Tagore Academy, Sector 3,<br />Ballabgarh, Faridabad Haryana</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/phone1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Phones</h6>
                                        <p><Link to="tel:2300548">  +91-2300548</Link>,  <Link to="tel:2241521"> +91 (0129) 2241521</Link>,<Link to="tel:22112177"> +91 (0129) 22112177</Link>  </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/mail1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Email</h6>
                                        <p><Link to="mailto:tagore_academy@rediffmail.com "> tagore_academy@rediffmail.com</Link> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>




                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs
