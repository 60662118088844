import { Link } from 'react-router-dom'
const Breadcrumb = ({  mid, breadcrumbName }) => {
    return(
        <>
            <div className="breadcrumb"> 
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li> {mid} </li>
                    <li>{breadcrumbName}</li>
                </ul>
            </div>
        </>
    )
}
export default Breadcrumb