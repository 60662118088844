import { useEffect, useState } from 'react';  
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
import {getPrincipalMessage} from'../Service/Api'
const DirectorMessage = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Director Message - Tagore Public School';  
      }, []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <Header />
            <Breadcrumb mid="About" breadcrumbName="Director's Message" />
                <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='title'>
                        <h3><span>Director's</span> Message</h3>
                        </div> 
                    </div>
                </div>
                
                {data?.length > 0 ? data?.filter((div) => div.category === "Director").map((item, index) => ( 
                    <div className="clearfix" key={index}>
                     <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="Tagore Public School Faridabad"/>
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <div dangerouslySetInnerHTML={{ __html: item.name }} /><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((item) => (
                    <div className="clearfix"> 
                    <div class="image_blk float-md-start msgimg">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/director.jpg" className="img-fluid col-md-4  mb-3 " alt="Tagore Public School Faridabad"/>
                <h5>Ms. Manorma Arora</h5>
                <p>Director</p>
                    </div>
                    <p>
                    The captain of this ship, Principal Ms. Manorma Arora, a Rajat Jayanti and State award winner is a dynamic personality with a clarity of vision. She is an educationist par excellence, a disciplinarian and a learned philosopher, known for giving qualitatively and quantitatively best results in Haryana State.
                    </p><p>For her happiness lies in the joy of achievement and in thrill of creative effort. "About two years back she shouldered the responsibility and has amalgamated both effort and intense training. The focus is on rejuvenating and revitalizing this existing system of education.
                    </p><p>The project is her dream and under her guidance "Tagore Academy has carved a niche for itself in the educational arena of the town.
                    </p><p>In the words of the Principal," Success is an outcome of Hardwork, Training and Discipline. This journey is on-going and uphill. There is no looking back, for growth is another name of activity. The School is epitome of universal values, that will produce citizens - responsible and progressive.
                            </p>
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/> 
     </>
  )
}

export default DirectorMessage
