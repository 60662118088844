
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const Activities = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Activities" breadcrumbName="Activities" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>School</span> Activities</h3>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="activities_blk">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/yoga.jpg" alt="tagore public school, Ballabhagarh" />
                                <h3>Yoga</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="activities_blk">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/chess.jpg" alt="tagore public school, Ballabhagarh" />
                                <h3>Chess</h3>                                
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="activities_blk">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/football.jpg" alt="tagore public school, Ballabhagarh" />
                                <h3>Football</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="activities_blk">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/art-craft.jpg" alt="tagore public school, Ballabhagarh" />
                                <h3>Art & Craft</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Activities
