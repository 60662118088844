
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const SchoolManagement = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="School Management" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>School </span> Management</h3>
                                
                            </div>
                        </div>
                       
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Education</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Onkar Singh Shekhawat</td>
                                        <td>Principal</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SchoolManagement
