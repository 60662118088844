import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import OnlineRegistration from '../Component/OnlineRegistration'
import Highlights from '../Component/Highlights'
const Header = () => {
    return (
        <>
            <Highlights />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 pos-re'>
                        <div className="header">

                            <div className="logo">
                                <NavLink to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/logo.png" alt="Tagore Public School, Ballabhagarh" className="img-fluid" /></NavLink>

                            </div>
                            <div className="aboutmidsec padded-sec">
                                <OnlineRegistration />
                                <div className='button bg-color'>
                                    <Link to="https://entab.online/TAACBH" target='_blank'>
                                        <p>Admission Info</p>
                                    </Link>
                                </div>
                                <div className='button bg-color'>
                                    <Link to="https://entab.online/TAACBH" target='_blank'>
                                        <p>Parent Login</p></Link>
                                </div>

                                <div className='button transparent-bg'>
                                    <Link to="/" target="_blank">
                                        <p>Blog</p>
                                    </Link>
                                </div>
                                <div className='button transparent-bg'>
                                    <Link to="/Pphelp" target='_blank'>
                                        <p>Help?</p>
                                    </Link>
                                </div>
                            </div>


                        </div>
                        <div className="header-nav">

                            <nav className="navbar navbar-expand-lg">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                                    <span className="navbar-toggler-icon"><i class="bi bi-list"></i></span>
                                </button>
                                <div className="collapse navbar-collapse" id="main_nav">
                                    <ul className="navbar-nav">
                                         <li className="nav-item"> <NavLink  className="nav-link" to="/">Home</NavLink></li> 
                                         <li className="nav-item dropdown">
                                         <div className="nav-link" data-bs-toggle="dropdown">   ABOUT  </div>
                                            <ul className="dropdown-menu fade-up"> 
                                            <li><NavLink className="dropdown-item" to="/Vision">Vision </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Infrastructure </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/Result">Results</NavLink></li> 
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Fee Structure</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Co-Curricular Activities </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Examination & Promotion </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">General Rules</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Attendance & Leave</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/ComingSoon">Academic Calendar</NavLink></li>
                                           </ul>  
                                      </li>
                                     
                                      <li className="nav-item dropdown">
                                      <div className="nav-link" data-bs-toggle="dropdown">   ADMISSIONS  </div>
                                         <ul className="dropdown-menu fade-up"> 
                                         <li> <NavLink className="dropdown-item" to="/ComingSoon">ADMISSIONS</NavLink></li> 
                                        </ul>  
                                   </li>

                                   <li className="nav-item dropdown">
                                   <div className="nav-link" data-bs-toggle="dropdown">   Facilities  </div>
                                      <ul className="dropdown-menu fade-up"> 
                                      <li> <NavLink className="dropdown-item" to="/ComingSoon">Camps & Tours</NavLink></li>
                                      <li> <NavLink className="dropdown-item" to="/ComingSoon">Library</NavLink></li>
                                      <li> <NavLink className="dropdown-item" to="/ComingSoon">Sports</NavLink></li>  
                                     </ul>  
                                </li>
                                        
                                        
                                        <li className="nav-item dropdown">
                                        <div className="nav-link" data-bs-toggle="dropdown">   ACTIVITIES  </div>
                                           <ul className="dropdown-menu fade-up"> 
                                           <li> <NavLink className="dropdown-item" to="/ComingSoon" target="_blank">ACTIVITIES</NavLink></li> 
                                          </ul>  
                                     </li>
  

                                        <li className="nav-item dropdown">
                                        <div className="nav-link" data-bs-toggle="dropdown">   Gallery  </div>
                                           <ul className="dropdown-menu fade-up"> 
                                              <li><NavLink className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li>
                                          </ul>  
                                     </li>
                                   
                                        <li className="nav-item"><NavLink className="nav-link" to="/ComingSoon"> ALUMNI</NavLink> </li>   
                                        <li className="nav-item"><NavLink className="nav-link" to="/cbse-disclousure"> CBSE DISCLOSURE </NavLink> </li>   
                                        <li className="nav-item"><NavLink className="nav-link" to="/contactUs"> CONTACT</NavLink> </li>    
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header
