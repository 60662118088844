import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlights = () => {
  const marqueeRef = useRef(null);
  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };
  const handleMouseOut = () => {
    marqueeRef.current.start();
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);


  const emptyArray = [
    { description: "Stay tuned for latest updates" }
  ];

  return (
    <>
      <div className="highlights">
        <div className='icon-announce'>
          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/announce.gif"></img>
        </div>
        <marquee onMouseOver={(event) => event.currentTarget.stop()} onMouseOut={(event) => event.currentTarget.start()}>
          {data.length > 0 ? (data.map((item, index) => (<p key={index}> {item.description}  {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image?url=${item.attachments[0]}`} target="_blank">  <i className="bi bi-paperclip"></i></Link>)}</p>
          ))
          ) : (
            emptyArray.map((data, index) => (
              <p key={index}><Link to="#" target="_blank">Stay Tuned For More Updates  <i className="bi bi-paperclip"></i></Link> </p>            ))
          )}

        </marquee>
        <div className='social_media'>
                <i class="bi bi-youtube"></i><i class="bi bi-facebook"></i>
                <i class="bi bi-twitter-x"></i><i class="bi bi-linkedin"></i>
                </div>
      </div>
    </>
  )
}

export default Highlights
