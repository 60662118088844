
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Home from'./Pages/Home'    
import News from'./Pages/News'  
import Events from'./Pages/Events'  
import Gallery from'./Pages/Gallery'  
import SubGallery from'./Pages/SubGallery'  
import VideoGallery from'./Pages/VideoGallery'  
import PrincipalMessage from'./Pages/PrincipalMessage'  
import DirectorMessage from'./Pages/DirectorMessage'  
import ChairmanMessage from'./Pages/ChairmanMessage'  
import ContactUs from'./Pages/contactUs'  
import AboutUs from'./Pages/AboutUs'  
import Cbsedisclousure from'./Pages/cbse-disclousure'
import Activities from'./Pages/activities'
import GeneralRules from'./Pages/general-rules'
import CampTour from'./Pages/campTour'
import Library from'./Pages/library' 
import Faculty from'./Pages/faculty'
import PageData from'./Pages/pageData'
import Infrastructure from'./Pages/Infrastructure'
import Alumni from'./Pages/alumni'
import SchoolManagement from'./Pages/school-management'
import Pphelp from'./Pages/Pphelp'
import ErpSoftware from'./Pages/ErpSoftware'
import Vision from'./Pages/Vision'
import Result from'./Pages/Result'
import Achivement from'./Pages/Achivement'
import Competition from'./Pages/Competition'
import Celebrations from'./Pages/Celebrations'
import Assemblies from'./Pages/Assemblies'
 import Activites from'./Pages/Activites' 
 import SportsGallery from "./Pages/SportsGallery";
 import ComingSoon from'./Pages/ComingSoon'
 import Tc from'./Pages/Tc'
 import Sports from "./Pages/sports";
 function App() {
  return (
      <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/News" element={<News/>} />
        <Route path="/Events" element={<Events/>} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/SubGallery" element={<SubGallery/>} />
        <Route path="/VideoGallery" element={<VideoGallery/>} />
        <Route path="/PrincipalMessage" element={<PrincipalMessage/>} />
        <Route path="/DirectorMessage" element={<DirectorMessage/>} />
        <Route path="/ChairmanMessage" element={<ChairmanMessage/>} />
        <Route path="/contactUs" element={<ContactUs/>} />
        <Route path="/AboutUs" element={<AboutUs/>} />
        <Route path="/cbse-disclousure" element={<Cbsedisclousure/>} />
        <Route path="/activities" element={<Activities/>} />
        <Route path="/general-rules" element={<GeneralRules/>} />
        <Route path="/campTour" element={<CampTour/>} />
        <Route path="/library" element={<Library/>} />
        <Route path="/Sports" element={<Sports/>} />
        <Route path="/faculty" element={<Faculty/>} />
        <Route path="/pageData" element={<PageData/>} />
        <Route path="/Infrastructure" element={<Infrastructure/>} />
        <Route path="/alumni" element={<Alumni/>} />
        <Route path="/school-management" element={<SchoolManagement/>} />
        <Route path="/Pphelp" element={<Pphelp/>} />
        <Route path="/ErpSoftware" element={<ErpSoftware/>} />
         <Route path="/Vision" element={<Vision/>} />
         <Route path="/Result" element={<Result/>} />
         <Route path="/Achivement" element={<Achivement/>} />
         <Route path="/Competition" element={<Competition/>} />
          <Route path="/Celebrations" element={<Celebrations/>} />
         <Route path="/Assemblies" element={<Assemblies/>} />
          <Route path="/Activites" element={<Activites/>} />
         <Route path="/SportsGallery" element={<SportsGallery/>} />
          <Route path="/ComingSoon" element={<ComingSoon/>} />
         <Route path="/Tc" element={<Tc/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
