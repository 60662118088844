import React, { useEffect, useState } from "react"; 
import { useNavigate } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";  
 import Header from "../Component/Header";
 import Footer from "../Component/Footer"; 
 import Breadcrumb from "../Component/Breadcrumb";
const SportsGallery = () => { 
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
      useEffect(() => {
      const fetchData = async () => {
           try{
             const galleryData = await getGalleryInner();
            console.log(galleryData);
             setData(galleryData);
           }catch (error) {
             console.log("Error fetching gallery data:",error)
           }finally{
             setLoading(false)
           }
        
        };
      fetchData();
      }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
        <Breadcrumb mid="Gallery" breadcrumbName="Sports" />
        <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="title">
                            <div>
                                <h3><span> Sports </span> </h3>
                            </div>
                        </div>
                        </div>
                    </div>
           
              <div className="row">
              {data.length > 0 ? (data?.filter((item) => item.school === "Senior"  &&  item.category === "Sports").map((item, index) => { 
                   
                  return (
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount" key={index}>
                    <div className="gal_new">
                      <article>
                        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} alt="Tagore Public School Faridabad" className="img-fluid" />
                        <div className="gal-content">
                          <h4 className="catgry-name">{item.title}</h4>
                          <p>{item.description}</p>
                          <h6 className="date"><i className="bi bi-calendar-week"></i> {item.formattedDate}
                            <span> <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}><i className="bi bi-arrow-right"></i> </a></span>
                          </h6>
                          <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
                        </div>
                      </article>
                    </div>
                  </div>
 
                  );
                })
              ) : (
                emptyData.map((data, index) => (
                  <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                        <div className="gal_new">
                          <article>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gallery2.png" alt="Tagore Public School Faridabad" className="img-fluid" />
                            <div className="gal-content">
                              <h4 className="catgry-name">Gallery Title</h4>
                              <p>Stay Tuned for Latest Updates</p>
                              <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY</h6>
                              <div className="total-img"><i className="bi bi-card-image"></i><p>0</p></div>
                            </div>
                          </article>
                        </div>
                      </div>
                ))
              )}
            </div> 
</div>
</div>
<Footer /> 
    </>
  )
}

export default SportsGallery
