
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const Vision = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Vision" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>Vision</span>  </h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                        <p><b>“Education is the most powerful weapon which you can use to change the World”.</b>  Nelson Mandela</p> 
                        <p>Tagore Academy Public School takes immense pride in being a vibrant community of passionate learners, united by a common mission and an unshakable commitment to excellence and triumph. </p> 
                        <p>The Mission of the school is <b> “A commitment to excellence”</b> and the vision is <b>“To accept Challenges” </b> in order to enable enriched learners. As a 21st Century learning organization, the school is dedicated to fostering the growth of responsive and motivated students   through a dynamic, success – oriented education program that empowers them with a global and local perspective.</p> 
                        <p>The school is committed to pursue a dynamic approach to learning that becomes a lifelong habit.  It endeavours to help every child develop into   a    holistic   personality. Every element of the school is designed to develop the cognitive and behavioral skills of the students by moulding   their innermost talents to create a positive expression, capable of right action and ability to take decision and nurture right attitude.</p> 
                        <p>The distinguished educators at Tagore Academy Public School with their futuristic vision have devised challenging strategies that help children evolve into responsible, caring and ethical world-citizens. Complimenting this, the school offers students an impressive array of co-curricular and enrichment programs. It also strives to develop students into modern <b>“Renaissance men and women”</b> with broad interests ready to cope with new challenges, generate novel ideas and venture into newer horizons.</p> 
                        <p>The Management and the staff are committed to this mission, and they work together to obtain exceptional results in academic, co-curricular, extra-curricular arenas and be happily productive in their own way at this school. The Management endeavours to make education a wholesome and enjoyable experience.</p> 
                        <p>In striving to provide a holistic learning experience delivered by highly qualified, professional staff within an outstanding infrastructure, the school will continue to   move dynamically into the future.</p> 
                        <p><b><i>“Children are living beings – more vibrant than grown up people who have built shells of habits around themselves. Therefore, it is absolutely necessary for their mental health and development that they should not have mere schools for their lessons, but a world whose guiding spirit is personal love.” </i></b> - Tagore 	</p>
                        </div> 
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Vision
