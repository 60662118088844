
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const AboutUs = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="About US" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>About</span> Us</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p><span>Founded in 1995, </span>TAPS is situated in NCR (National Capital Region) in Ballabgarh, Faridabad, adjacent to New Delhi. It houses <span>3500 students,</span> coming from all walks of life and a dedicated team of 150, proficient and skilled teachers and lecturers. The school is headed by Director Ms. Manorma Arora, a Rajat Jayanti and State Award Winner. She is a dynamic personality with a clarity of vision, an educationist par excellence and a learned philosopher.</p>
                            <p>Since it’s inception the school has provided congenial & conducive atmosphere for the multifaceted development of the students. The activity based curriculum for class Nursery to XII, is framed to inculcate & instill educational, ethical & professional attributes in the students. It has been an endeavour on the part of the management & the staff to strive for excellence, enabling the institute to yield young, enterprising professionals & human beings.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/about.jpg" className="img-fluid about_section"></img>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutUs
