import React from 'react';
import ContactBranch from '../Component/ContactBranch'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer padded-sec">
        <div className='abs-list ftrShape1'> 
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-4 col-lg-6 col-sm-6 col-xs-12'>
              <div className='ftr-logo'>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/ftr-logo.png" className='img-fluid' alt="Hill Top School" />
                <div className='social-media'>
                <i class="bi bi-youtube"></i><i class="bi bi-facebook"></i>
                <i class="bi bi-twitter-x"></i><i class="bi bi-linkedin"></i>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <div className='quick-link'>
                <h3>Quick Links</h3>
                <ul>
                  <li><Link to="/"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Home</Link></li>
                  <li><Link to="/Aboutus"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> About Us</Link></li>
                  <li><Link to="/"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> School Management</Link></li>
                  <li><Link to="https://entab.online/TAACBH" target="_blank"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Admissions</Link></li>
                  <li><Link to="/PrincipalMessage"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Principal’s Desk</Link></li>
                  <li><Link to="/faculty"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Staff List</Link>  </li> 
                  <li><Link to="/VideoGallery"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Video Gallery</Link></li>
                  <li><Link to="/ErpSoftware"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> ERP Software</Link></li>
                  <li><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Student Login</li>
                  <li><Link to="/Pphelp"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Parent Login</Link></li>
                  <li><Link to="/Tc"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Transfer  Certificate</Link></li>
                  <li><Link to="https://entab.online/TAACBH" target="_blank"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/arraow.png' alt="Tagore Public School, Ballabhgarh" /> Alumni Login</Link></li>
                </ul>
              </div>
            </div>
           
            <div className='col-xl-2 col-lg-6 col-sm-6 col-xs-12 address'>
              <h3>Location</h3>
              <p><i class="bi bi-geo-alt-fill"></i> <span>Tagore Academy, Sector 3,<br></br> Ballabgarh, Faridabad Haryana</span></p>
              <p><i class="bi bi-telephone-fill"></i> <span><a href="tel: 0129 - 2241521">+91 (0129) 2241521,</a>
              <a href="tel: 22112177">22112177</a>,  <a href="tel:2300548">2300548</a></span></p>
              <p><i class="bi bi-globe"></i> <span> <a href="tagoreacademy.org.in">tagoreacademy.org.in</a> 
              </span></p>
            </div>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <ContactBranch />
              </div>
          </div>
       
        </div>
      </div>
      <div className='copyright'>
      Copyright©️2024 by Tagore Academy, ballabgarh | Created by Entab Infotech : Campuscare®️  
      </div>
    </>
  );
}

export default Footer;
