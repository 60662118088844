import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/TAGOREBG`);
    console.log(data)
    return data;
} 
export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/TAGOREBG`);
    return data;
} 
export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/TAGOREBG`);
    console.log("news:",data)
    return data;
} 
export const getNewsInner = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/all/TAGOREBG`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/TAGOREBG`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/TAGOREBG`);
    console.log(data)
    return data;
} 

export const getGallery = async() => {
    try {
        const { data } = await axios.get(API_URL + `/api/gallery/TAGOREBG`);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error fetching gallery:', error);
    }
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/TAGOREBG`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/TAGOREBG`,getHeader());
    console.log(data)
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/TAGOREBG`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/TAGOREBG`,getHeader());
    console.log("online registration Data", data)
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/TAGOREBG`,getHeader());
    return data;
}

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/TAGOREBG`, getHeader());
    return data;
} 

export const getMedia = async() => {
    const { data } = await axios.get(API_URL + `/api/media/TAGOREBG`, getHeader());
    console.log(data);
    return data;
} 
export const getVideoGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/TAGOREBG`, getHeader());
    return data;
} 
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/TAGOREBG`, getHeader());
    console.log(data);
    return data;
} 
export const getReading = async() => {
    const { data } = await axios.get(API_URL + `/api/reading/all/TAGOREBG`, getHeader());
    console.log(data);
    return data;
} 
export const getTestimonial = async() => {
    const { data } = await axios.get(API_URL + `/api/testimonials/TAGOREBG`, getHeader());
    console.log(data);
    return data;
} 
export const getFaculty = async() => {
    const { data } = await axios.get(API_URL + `/api/faculty/all/TAGOREBG`, getHeader());
    console.log(data);
    return data;
} 

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/TAGOREBG`,{name,admission},getHeader());
    return data;
}


// export const form = async (formData, header) => {
//     try {
//         const { data } = await axios.post(`${API_URL}/api/form/LFSGV`, formData, header);
//         return data;
//     } catch (error) {
//         console.error('Error in form submission:', error);
//         if (error.response) {
//             console.error('Error response data:', error.response.data);
//             console.error('Error response status:', error.response.status);
//         }
//         throw error;
//     }
// };
