import React, { useState, useEffect } from 'react';
import { getGallery } from '../Service/Api';
import { Link } from 'react-router-dom';

const Gallery = () => { 
    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const categories = [
    { name: "Activities", path: "/Activites", placeholder: "about.jpg" },
    { name: "Competitions", path: "/Competition", placeholder: "about.jpg" },
    { name: "Assemblies", path: "/Assemblies", placeholder: "about.jpg" },
    { name: "Achievements", path: "/Achivement", placeholder: "about.jpg" },
    { name: "Celebrations", path: "/Celebrations", placeholder: "about.jpg" },
    { name: "Sports", path: "/Sports", placeholder: "about.jpg" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderCategory = (category) => {
    const categoryData = data.find(item => item.school === "Senior" && item.category === category.name);

    return (
  
      <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6" key={category.name}>
          <div className="galleryimg">
            <div className="galleryimgbox">
            <Link to={category.path}>
               <img
            src={
              categoryData
                ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${categoryData.attachments[0]}`
                : `https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/${category.placeholder}`
            }
            className="img-fluid"
          />
          <span> View More</span>
          </Link>
          </div>
          <div className="gallerydesc"> 
            
              <h3>{category.name}</h3> 
            
          </div>
         
      </div>
      </div>
    );
  };

  return (
    <div className="row gallery-box">
      { 
        categories.map(renderCategory)
      }
    </div>
  );
};
export default Gallery;
